import React from 'react';

// Components
import Layout from '../../../components/Layout';
import AboutBanner from '../../../components/AboutBanner';

// Images
import Hero from '../../../img/about_01_m_w.jpg';
import MobHero from '../../../img/about_01_m_h.jpg';
import Case from '../../../img/about_01_p_01.jpg';

// Language
import { getPackOf } from '../../../lang';
const LANG = getPackOf('zh');

const AboutJinXing = () => {
  return (
    <Layout>
      <AboutBanner pageTitle={LANG.公司簡介} subTitle={LANG.公司簡介副標題} bgImg={Hero} mobileBgImg={MobHero} />
      <section id="case" className="py-5">
        <div className="container">
          <div className="d-flex flex-wrap">
            <div className="col-12 pb-3">
              <img src={Case} alt="" width="180" height="180" className="img-thumbnail float-right ml-3" />
              <p>{LANG.公司簡介內文}</p>
            </div>
            {[
              { title: LANG.半自動化工廠, content: LANG.半自動化工廠內文 },
              { title: LANG.世界級素材, content: LANG.世界級素材內文 },
              { title: LANG.客製化服務, content: LANG.客製化服務內文 },
            ].map((list) => {
              return (
                <div className="col-12 col-md-4 my-1" key={list.title}>
                  <div className="card bg-secondary">
                    <p className="card-text h6 p-1 text-white">
                      <strong>{list.title}</strong>
                    </p>
                    <div className="card bg-white">
                      <p className="card-text p-1">{list.content}</p>
                    </div>
                  </div>
                </div>
              );
            })}
            <div id="" className="col-12 pb-3">
              <hr className="my-4" />
            </div>
            <div className="col-12 mb-3">
              <p className="h4">
                <strong>{LANG.景欣企業三大服務特點}</strong>
              </p>
            </div>
            {[LANG.景欣企業三大服務特點1, LANG.景欣企業三大服務特點2, LANG.景欣企業三大服務特點3].map((feature, i) => {
              return (
                <div className="col-12 col-md-4 my-1">
                  <div className="row mx-0 bg-warning rounded">
                    <div className="col-3 text-center">
                      <span className="display-4">
                        <strong>{i + 1}</strong>
                      </span>
                    </div>
                    <div className="col-9 align-self-center border-left border-white">
                      <span>{feature}</span>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="col-12 mt-2 mb-5">
              <div className="row mx-0 bg-light border border-secondary rounded p-2">
                <span>{LANG.景欣企業服務特點總結}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutJinXing;
